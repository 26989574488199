.navbar {
  z-index: 10;
}

.hvr_underline_from_left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr_underline_from_left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #f3a736;
  height: 4px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr_underline_from_left:hover:before,
.hvr_underline_from_left:focus:before,
.hvr_underline_from_left:active:before {
  right: 0;
}

@-webkit-keyframes hvr-icon-up {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
  75% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}
